.board-agent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 50vw;
  height: 8vh;
  font-size: 16pt;
  border-bottom: 1px solid #55b357;
  border-radius: 5px;
  margin: 1vh 0
}

.rank {
  font-weight: bold;
  font-size: 18pt;
  padding: 0 10px;
}

.level {
  font-size: 18pt;
  padding: 0 10px;
}

.agent-name {
  margin: 0;
  padding: 0 10px;
  line-height: 1;
}

.people {
  font-size: 18pt;
}

.people:hover {
  text-decoration: none;
}