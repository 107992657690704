.dashboard {
  padding: 10px 20px;
}

.banco {
  height: 12em !important; /* Override CSS @ AgentDetails.css */
}

.division {
  border: 1px solid #55b357;
}