body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  padding: 0 2vw;
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.topbar-link {
  color: #FFFFFF;
  display: inline-block;
  width: 45vw;
}

.main-menu {
  text-align: left;
}

.authentication {
  text-align: right;
  cursor: pointer;
}

.topbar-link:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.login-form {
  padding: 20px auto 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input-login--rounded {
  width: 25%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.btn-margin {
  margin: 2vh auto;
}
.user-form {
  padding: 20px auto 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input--rounded {
  width: 50%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 50%;
}

.checkbox {
  display: inline;
  -webkit-transform: scale(1.6);
          transform: scale(1.6)
}

.agent-form-complement {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.input--rounded--complement {
  width: 100%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.multiselect {
  width: 100%;
}

.btn-margin {
  margin: 3vh;
}
/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

.profile {
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

/* Key information section */

.profile-head {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 50vw;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

.profile-head * {
  display: block;
}

.profile-picture {
  width: 125px;
  height: 150px;
  border-radius: 25px;
  border: 1px solid #55b357;
}

.profile-description {
  width: 50vw;
  margin: 1vh auto 3vh;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.33em;
  box-shadow: 4px 4px 5px #DDD;
  color: #212529;
  resize: none;
}

/* Medals section */

.medal-container {
  margin: 0 auto;
  width: 75%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.medal {
  margin: 1vh auto;
  width: 30%;
  height: 120px;
  background-color: #4c95c2;
  border: 3px solid #041849;
}

.xp-container {
  margin: 0 auto 2vh;
}

.xp-circle {
  height: 20vh;
  width: 20vh;
}

/* Hobbies section */

.hobbies {
  width: 50vw;
  font-size: 1.33em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0;
}

.hobby-item {
  list-style: none;
  display: block;
  font-weight: bold;
}

.hobby-input {
  border-radius: 5px;
  border: 2px solid gray;
  box-shadow: 4px 4px 5px #DDD;
  resize: none;
}

.erase-hobby {
  font-size: 0.8em;
  background-color: none;
  background: none;
  border: none;
  padding: 10px;
  color: #AA3333
}

.hobby-button {
  display: block;
  margin: 0 auto;
}
.suggestions-form {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input--rounded {
  width: 50%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 50%;
}

.checkbox {
  display: inline;
  -webkit-transform: scale(1.6);
          transform: scale(1.6)
}
.message-form {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input--rounded {
  width: 50%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 50%;
}

.checkbox {
  display: inline;
  -webkit-transform: scale(1.6);
          transform: scale(1.6)
}
/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

.board-post {
  margin: 2vh auto;
  padding: 10px;
  width: 75%;
  border: 2px solid #041849;
  border-radius: 5px;
}
.message-board {
  padding: 2vh 2vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

/* Secciones */
.agent-details {
  padding: 10px 20px;
}

.comisiones {
  margin: 2vh auto;
}

.frase--matona {
  margin: 2vh auto;
}

/* Estilos de componentes */

.agent-name {
  margin: 4vh auto;
  font-size: 32pt;
  font-weight: lighter;
  color: #041849;
}

.banco {
  margin: 5px auto;
  border-bottom: 2px solid #145b81;
  height: 153px;
}

.banco-top {
  border-top: 2px solid #145b81;
}

.border-right {
  border-right: 2px solid #145b81;
}

.fee {
  font-weight: bold;
  font-size: 1.5em;
  color: #041849;
}

.eval-text {
  font-size: 1.5em;
  color: #145b81;
}

.frase--motivacional {
  font-size: 1.5em;
}
.dashboard {
  padding: 10px 20px;
}

.banco {
  height: 12em !important; /* Override CSS @ AgentDetails.css */
}

.division {
  border: 1px solid #55b357;
}
.monthly-result {
  margin: 5px auto;
  border-bottom: 2px solid #145b81;
  height: 20vh;
}

.monthly-result-top {
  border-top: 2px solid #145b81;
}

.month-result-container {
  margin: 5vh auto 0;
}
.input--rounded {
  margin: 2vh 1vh;
}

.seleccionar {
  font-size: 1.25em;
}

.file-upload {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

p {
  margin: 0;
}

h2 {
  margin: 3vh auto 0;
}
.input--rounded {
  margin: 2vh 1vh;
}

.seleccionar {
  font-size: 1.25em;
}

.file-upload {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

p {
  margin: 0;
}

h2 {
  margin: 3vh auto 0;
}
.board-agent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
  width: 50vw;
  height: 8vh;
  font-size: 16pt;
  border-bottom: 1px solid #55b357;
  border-radius: 5px;
  margin: 1vh 0
}

.rank {
  font-weight: bold;
  font-size: 18pt;
  padding: 0 10px;
}

.level {
  font-size: 18pt;
  padding: 0 10px;
}

.agent-name {
  margin: 0;
  padding: 0 10px;
  line-height: 1;
}

.people {
  font-size: 18pt;
}

.people:hover {
  text-decoration: none;
}
.leaderboard {
  padding: 20px;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-weight: bold;
  font-size: 14pt;
}

.agente-header {
  padding: 0 30px;
}
/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

h3, h4 {
  font-weight: normal !important;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  background-image: -webkit-linear-gradient(45deg, #041849, #55b357);
  background-image: linear-gradient(45deg, #041849, #55b357);
  /* background-color: #55b357; */
  min-height: 8vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.has-slider {
  max-height: 92vh;
  overflow-y: auto;
}

h1 {
  margin: 2vh auto;
}

.welcome-img {
  width: 50vw;
}
