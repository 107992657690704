.login-form {
  padding: 20px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input-login--rounded {
  width: 25%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.btn-margin {
  margin: 2vh auto;
}