/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

.board-post {
  margin: 2vh auto;
  padding: 10px;
  width: 75%;
  border: 2px solid #041849;
  border-radius: 5px;
}