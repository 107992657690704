.leaderboard {
  padding: 20px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 14pt;
}

.agente-header {
  padding: 0 30px;
}