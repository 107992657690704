/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

h3, h4 {
  font-weight: normal !important;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  background-image: linear-gradient(45deg, #041849, #55b357);
  /* background-color: #55b357; */
  min-height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.has-slider {
  max-height: 92vh;
  overflow-y: auto;
}

h1 {
  margin: 2vh auto;
}

.welcome-img {
  width: 50vw;
}