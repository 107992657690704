/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

/* Secciones */
.agent-details {
  padding: 10px 20px;
}

.comisiones {
  margin: 2vh auto;
}

.frase--matona {
  margin: 2vh auto;
}

/* Estilos de componentes */

.agent-name {
  margin: 4vh auto;
  font-size: 32pt;
  font-weight: lighter;
  color: #041849;
}

.banco {
  margin: 5px auto;
  border-bottom: 2px solid #145b81;
  height: 153px;
}

.banco-top {
  border-top: 2px solid #145b81;
}

.border-right {
  border-right: 2px solid #145b81;
}

.fee {
  font-weight: bold;
  font-size: 1.5em;
  color: #041849;
}

.eval-text {
  font-size: 1.5em;
  color: #145b81;
}

.frase--motivacional {
  font-size: 1.5em;
}