nav {
  padding: 0 2vw;
  display: flex;
  width: 100vw;
  justify-content: space-around;
}

.topbar-link {
  color: #FFFFFF;
  display: inline-block;
  width: 45vw;
}

.main-menu {
  text-align: left;
}

.authentication {
  text-align: right;
  cursor: pointer;
}

.topbar-link:hover {
  color: #FFFFFF;
  text-decoration: none;
}