.input--rounded {
  margin: 2vh 1vh;
}

.seleccionar {
  font-size: 1.25em;
}

.file-upload {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0;
}

h2 {
  margin: 3vh auto 0;
}