/* 
Verde coru: #55b357
Azul claro coru: #4c95c2
Azul mediano coru: #145b81
Azul oscuro coru: #041849
*/

.profile {
  padding: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

/* Key information section */

.profile-head {
  display: flex;
  flex-flow: row wrap;
  width: 50vw;
  justify-content: space-evenly;
  align-items: center;
}

.profile-head * {
  display: block;
}

.profile-picture {
  width: 125px;
  height: 150px;
  border-radius: 25px;
  border: 1px solid #55b357;
}

.profile-description {
  width: 50vw;
  margin: 1vh auto 3vh;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.33em;
  box-shadow: 4px 4px 5px #DDD;
  color: #212529;
  resize: none;
}

/* Medals section */

.medal-container {
  margin: 0 auto;
  width: 75%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.medal {
  margin: 1vh auto;
  width: 30%;
  height: 120px;
  background-color: #4c95c2;
  border: 3px solid #041849;
}

.xp-container {
  margin: 0 auto 2vh;
}

.xp-circle {
  height: 20vh;
  width: 20vh;
}

/* Hobbies section */

.hobbies {
  width: 50vw;
  font-size: 1.33em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0;
}

.hobby-item {
  list-style: none;
  display: block;
  font-weight: bold;
}

.hobby-input {
  border-radius: 5px;
  border: 2px solid gray;
  box-shadow: 4px 4px 5px #DDD;
  resize: none;
}

.erase-hobby {
  font-size: 0.8em;
  background-color: none;
  background: none;
  border: none;
  padding: 10px;
  color: #AA3333
}

.hobby-button {
  display: block;
  margin: 0 auto;
}