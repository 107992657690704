.user-form {
  padding: 20px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin: 2vh auto;
  font-size: 1.5em;
  font-weight: lighter;
}

.input--rounded {
  width: 50%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.checkbox-container {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  width: 50%;
}

.checkbox {
  display: inline;
  transform: scale(1.6)
}

.agent-form-complement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input--rounded--complement {
  width: 100%;
  padding: 5px;
  border: 2px solid #041849;
  border-radius: 5px;
  font-size: 1.25em;
}

.multiselect {
  width: 100%;
}

.btn-margin {
  margin: 3vh;
}